import { Heading, Text } from '@chakra-ui/react';

export const H1 = (props) => (
  <Heading as='h1' fontSize='xl' fontWeight='regular' {...props} />
);

export const H2 = (props) => (
  <Heading as='h2' fontSize='lg' fontWeight='regular' {...props} />
);

export const H3 = (props) => (
  <Heading as='h3' fontSize='md' fontWeight='regular' {...props} />
);

export const H4 = (props) => (
  <Heading as='h4' fontSize='md' fontWeight='bold' {...props} />
);

export const P = (props) => <Text {...props} />;
